import './App.css';
import React, { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import indexedDBService from './services/IndexedDBService';
import GameService from './services/GameService';

const DEFAULT_TIMEOUT = 2500;

function App() {
  const [loading, setLoading] = useState(true);
  const [gameService] = useState(new GameService(indexedDBService));
  const [quote, setQuote] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [score, setScore] = useState(0);
  const [count, setCount] = useState(-1);
  const [hardMode, setHardMode] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        await gameService.initializeGame();
        await generateQuestion();
        setLoading(false);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, [gameService]);

  useEffect(() => {
    const updateOptions = async () => {
      if (!loading && quote) {  // Only update if game is loaded and we have a quote
        const newOptions = await gameService.getOptionsForBook(quote.bookId);
        setOptions(newOptions);
        setSelectedOption(null);
        setIsCorrect(null);
        setShowCorrectAnswer(false);
      }
    };

    updateOptions();
  }, [hardMode]);

  const generateQuestion = async () => {
    const [quote, options] = await gameService.getQuoteAndOptions(hardMode);
    setQuote(quote);
    setOptions(options);
    setSelectedOption(null);
    setIsCorrect(null);
    setShowCorrectAnswer(false);
    setCount(count + 1)
  };

  const getLoadQuestionMethod = () => {
    const method = localStorage.getItem('loadQuestionMethod');
    return method === 'enter' ? 'enter' : 'auto';
  };

  const handleOptionClick = async (option) => {
    setSelectedOption(option);
    if (option.id === quote.bookId) {
      setIsCorrect(true);
      setScore(score + 1)
      await new Promise(resolve => setTimeout(resolve, DEFAULT_TIMEOUT));
      if (getLoadQuestionMethod() === 'auto') {
        await generateQuestion();
      }
    } else {
      setIsCorrect(false);
      setShowCorrectAnswer(true);
      await new Promise(resolve => setTimeout(resolve, DEFAULT_TIMEOUT));
      if (getLoadQuestionMethod() === 'auto') {
        await generateQuestion();
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === 'Enter' && 
          selectedOption !== null && 
          getLoadQuestionMethod() === 'enter') {
        await new Promise(resolve => setTimeout(resolve, 0));
        await generateQuestion();
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [selectedOption, options, quote, isCorrect, showCorrectAnswer]);

  return (
    <div className="App">
      {loading ? (
        <div className="loading-container">
          <Oval
            height={80}
            width={80}
            color="#47b9f9"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#a7c9dc"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="quiz-container">
          <div className="header-container">
            <div></div>
            <div className="score">Score: {score}/{count}</div>
            <div className="toggle-container">
              <span className="toggle-label">Hard Mode</span>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={hardMode}
                  onChange={(e) => setHardMode(e.target.checked)}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
          </div>
          <p className="quote">"{quote.text}"</p>
          <div className="options">
            {options.map((option) => {
              const shouldShow = !selectedOption || 
                               selectedOption.id === option.id || 
                               (showCorrectAnswer && option.id === quote.bookId);
              
              return shouldShow ? (
                <button
                  key={option.id}
                  onClick={() => handleOptionClick(option)}
                  className={`option-button ${
                    selectedOption && selectedOption.id === option.id 
                      ? (isCorrect ? 'correct' : 'incorrect') 
                      : ''
                  } ${
                    showCorrectAnswer && option.id === quote.bookId 
                      ? 'highlight-correct' 
                      : ''
                  }`}
                >
                  <span className="title">{option.title}</span>
                  <span className="author">{option.author.join(', ')}</span>
                </button>
              ) : null;
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
